<template>
    <!--    <div class="nav sidebar">-->
    <!--        <el-menu-->
    <!--                default-active="2"-->
    <!--                class="el-menu-vertical-demo sidebar-el-menu"-->
    <!--                @open="handleOpen"-->
    <!--                @close="handleClose"-->
    <!--                background-color="rgb(50, 65, 87)"-->
    <!--                text-color="#fff"-->
    <!--                active-text-color="#ffd04b"-->
    <!--                router="router"-->
    <!--                :collapse="isCollapse"-->
    <!--        >-->
    <!--            <el-submenu :index="item.index" v-for="item in items">-->
    <!--                <template slot="title">-->
    <!--                    <i :class="item.icon"></i>-->
    <!--                    <span>{{item.title}}</span>-->
    <!--                </template>-->
    <!--                <el-menu-item-group>-->
    <!--                    <el-menu-item :index="submenu.index" v-for="submenu in item.subs">{{submenu.title}}</el-menu-item>-->
    <!--                </el-menu-item-group>-->
    <!--            </el-submenu>-->
    <!--        </el-menu>-->

    <!--    </div>-->

    <div class="nav sidebar nj-nav" style=" overflow-y: auto;">
        <div class="nj-nav-menu">
            <ul>
                <el-col v-for="(item,index) in menuList" :key="index">
                    <li v-if="item.is_nav==1" @click="showSubs(item,index)" :class="index==[curPage]?'active':''">
                        <i :class="item.ico"></i>
                        <div class="mt-5">{{item.name}}</div>
                        <i class="el-icon-caret-left"></i>
                    </li>
                </el-col>
            </ul>
        </div>
        <div class="nj-nav-group" style="overflow-y: auto;" v-if="menuList.length && menuList[curPage].is_nav==1">
            <div class="nj-nav-title"><i class="mr-5" :class="menuList[curPage].ico"></i>{{menuList[curPage].group_name}}
            </div>
            <div>
                <div class="nj-nav-item" v-for="(submenu,skey) in menuList[curPage].subs" :key="skey"
                     :class="submenu.path==$route.path?'active':''">
                    <router-link :to="submenu.path" v-if="submenu.is_nav==1">{{submenu.name}}</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import bus from '../assets/js/bus';
    import {mapState} from "vuex";

    export default {
        name: 'Nav',
        data() {
            return {
                isCollapse: false,
                itemId: 0,
                items: [
                    {
                        icon: 'el-icon-s-home',
                        index: '/home',
                        title: '首页',
                        groupTitle: '系统首页',
                        subs: [
                            {
                                index: '/home',
                                title: '系统首页'
                            },
                            {
                                index: '/order/list',
                                title: '订单管理'
                            },
                            {
                                index: '',
                                title: '员工管理'
                            },
                            {
                                index: '',
                                title: '车险档案'
                            },
                            {
                                index: '/notice',
                                title: '系统公告'
                            }
                        ]
                    },
                    {
                        icon: 'el-icon-s-order',
                        index: '/order/index',
                        title: '订单',
                        groupTitle: '订单管理',
                        subs: [
                            {
                                index: '/order/index',
                                title: '订单概况'
                            },
                            {
                                // index:'/order/list',
                                index: '',
                                title: '订单管理'
                            },
                            {
                                // index:'/order/report',
                                index: '',
                                title: '每日报表'
                            }
                        ]
                    },
                    {
                        icon: 'el-icon-set-up',
                        index: '/channelpage1',
                        title: '渠道',
                        groupTitle: '渠道管理',
                        subs: [
                            {
                                index: '/channelpage1',
                                title: '入驻审核'
                            },
                            {
                                index: '',
                                title: '人员管理'
                            },
                            {
                                index: '',
                                title: '销售排行'
                            },
                            {
                                index: '',
                                title: '服务上架'
                            },
                            {
                                index: '',
                                title: '奖励设置'
                            }
                        ]
                    },
                    {
                        icon: 'el-icon-user-solid',
                        index: '/customerpage1',
                        title: '客户',
                        groupTitle: '客户管理',
                        subs: [
                            {
                                index: '/customerpage1',
                                title: '车辆管理'
                            }
                        ]
                    },
                    {
                        icon: 'el-icon-s-comment',
                        index: '/',
                        title: '微信',
                        groupTitle: '微信管理',
                        subs: [
                            {
                                index: '',
                                title: '微信消息'
                            },
                            {
                                index: '',
                                title: '自动回复'
                            },
                            {
                                index: '',
                                title: '文章管理'
                            },
                            {
                                index: '',
                                title: '参数二维码'
                            },
                            {
                                index: '',
                                title: '自定义菜单'
                            },
                            {
                                index: '',
                                title: '微信配置'
                            }
                        ]
                    },
                    {
                        icon: 'el-icon-s-claim',
                        index: '/',
                        title: '绩效',
                        groupTitle: '绩效管理',
                        subs: [
                            {
                                index: '',
                                title: '员工列表'
                            },
                            {
                                index: '',
                                title: '员工绩效'
                            },
                            {
                                index: '',
                                title: '奖惩设置'
                            },
                            {
                                index: '',
                                title: '岗位提成'
                            }
                        ]
                    },
                    {
                        icon: 'el-icon-bank-card',
                        index: '/',
                        title: '财务',
                        groupTitle: '财务管理',
                        subs: [
                            {
                                index: '',
                                title: '台账统计'
                            },
                            {
                                index: '',
                                title: '项目统计'
                            },
                            {
                                index: '',
                                title: '代办统计'
                            },
                            {
                                index: '',
                                title: '流水查询'
                            },
                            {
                                index: '',
                                title: '支出管理'
                            },
                            {
                                index: '',
                                title: '工资管理'
                            }
                        ]
                    },
                    {
                        icon: 'el-icon-_data-line',
                        index: '/',
                        title: '数据',
                        groupTitle: '数据管理',
                        subs: [
                            {
                                index: '',
                                title: '营收分析'
                            },
                            {
                                index: '',
                                title: '业务分析'
                            },
                            {
                                index: '',
                                title: '客户分析'
                            }
                        ]
                    },
                    {
                        icon: 'el-icon-edit-outline',
                        index: '/',
                        title: '事务',
                        groupTitle: '事务管理',
                        subs: [
                            {
                                index: '',
                                title: '事务概况'
                            },
                            {
                                index: '',
                                title: '提醒管理'
                            },
                            {
                                index: '',
                                title: '设备管理'
                            }
                        ]
                    },
                    {
                        icon: 'el-icon-s-tools',
                        index: '/system/addSite',
                        title: '系统',
                        groupTitle: '系统管理',
                        subs: [
                            {
                                index: '/system/addSite',
                                title: '站点信息'
                            },
                            {
                                index: '/system/permissions',
                                title: '角色权限'
                            },
                            {
                                index: '',
                                title: '站点数据查看权限'
                            }
                        ]
                    }
                ],
                curPage: 0
            }
        },
        methods: {
            showSubs(item, index) {
                this.curPage = index;
                this.$router.push(item.path);
            },
            render() {
                this.menuList.map((val, index) => {
                    if (val.subs.length > 0) {
                        val.subs.map((sval, sindex) => {
                            if (sval.path === this.$route.path) {
                                this.curPage = index;
                            }
                        })
                    }
                });
            }
        },
        mounted() {
            this.render()

        },
        created() {
            // 通过 Event Bus 进行组件间通信，来折叠侧边栏
            bus.$on('collapse', msg => {
                this.collapse = msg;
            })
        },
        updated() {
            this.render();
        },
        computed: {
            onRoutes() {
                return this.$route.path.replace('/', '');
            },
            ...mapState({
                menuList: state => state.menus
            })
        },
        watch: {
            $route(to) {
            }
        }
    }
</script>
<style lang="less">
    .nav {
        /*height: 100%;*/
        background-color: #f7f7f7;
        border-right: #ddd solid 1px;
    }

    .el-aside::-webkit-scrollbar {
        display: none;
    }

    .el-menu-item:focus, .el-menu-item:hover {
        background-color: rgb(40, 52, 70) !important;
    }

    .el-submenu__title:hover {
        background-color: rgb(40, 52, 70) !important;
    }

    .el-menu {
        border-right: none;
        text-align: left;
        height: 100%;
    }

    .el-menu--collapse > .el-menu-item span, .el-menu--collapse > .el-submenu > .el-submenu__title span {
        display: block;
        height: 20px;
        width: 30px;
        visibility: visible;
    }

    .nj-nav {
        position: relative;

        &-menu {
            width: 80px;
            height: 100%;
            cursor: pointer;
            /*background-color: rgb(50, 65, 87);*/
            background-color: rgba(55, 61, 65, 1); //55, 61, 65, 1 51, 55, 68, 1
            text-align: center;
            color: #909399;
            font-size: 14px;

            i {
                font-size: 18px;
            }

            li {
                position: relative;
                padding: 15px 0;
                color: #ccc;
            }

            li.active, li:hover {
                /*background-color: #283446;*/
                background-color: rgba(0, 0, 0, .5);
                color: #fff;
            }

            li .el-icon-caret-left {
                display: none;
                position: absolute;
                right: -6px;
                top: 50%;
                margin-top: -10px;
                color: #f7f7f7;
            }

            li.active .el-icon-caret-left {
                display: block;
            }
        }

        &-title {
            height: 50px;
            line-height: 50px;
            padding-left: 20px;
            background-color: rgba(242, 242, 242, 1);
            border-bottom: #ddd solid 1px;
            font-size: 14px;
            font-weight: 700;
        }

        &-group {
            position: absolute;
            top: 0;
            left: 80px;
            right: 0;
        }

        &-item {
            font-size: 14px;
        }

        &-item.active {
            background-color: #fff;
            color: rgb(0, 121, 254);
        }

        &-item a {
            display: block;
            color: #333;
            padding: 12px 20px 12px 40px;
        }

        &-item:hover a {
            color: rgb(0, 121, 254);
        }

        &-item.active a {
            color: rgb(0, 121, 254);
        }

        .el-submenu__title {
            height: 60px;
            line-height: 26px;
        }

        .el-menu--collapse {
            width: 80px;
        }

    }

    .el-menu--vertical {
        display: none !important;
    }
</style>