<template>
    <el-dialog
            title="用户信息"
            :visible.sync="wxchatDialogVisible"
            width="30%"
            center>
        <span>和微信用户进行对话</span>
        <span slot="footer" class="dialog-footer">
                <el-button @click="close">取 消</el-button>
                <el-button type="primary" @click="close">确 定</el-button>
            </span>
    </el-dialog>
</template>

<script>
    export default {
        name: "Wxchat",
        props: { //接受父组件传递来的数据
            uid: {
                type: Number
            }
        },
        mounted() {
            console.log(this.uid)
        },
        data() {
            return {
                wxchatDialogVisible: true
            }
        },
        methods: {
            close() {
                this.wxchatDialogVisible = false
                this.$emit('closeWxchat', false)
            }
        }
    }
</script>
<style scoped>
</style>