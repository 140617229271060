<template>
    <div>
        <css href="//at.alicdn.com/t/font_1896021_37x41oa4sxj.css"/>
        <el-container>
            <el-header>
                <Header/>
            </el-header>
            <el-container>
                <el-aside :style="{'width':sideBarWidth+'px'}">
                    <Nav/>
                </el-aside>
                <el-main>
                    <Tabs :style="{'left':leftWidth+'px'}"/>
                    <div class="nj-container" ref="container">
                        <router-view/>
                    </div>
                </el-main>
            </el-container>
        </el-container>
        <wxchat v-if="dialogWxchatVisible" @closeWxchat="closeWxchat" :uid="chatUid"/>
        <el-dialog title="你的登陆已失效,请重新登陆后继续操作" :visible.sync="showLogoutAlert" :close-on-click-modal="false"
                   :show-close="false" width="30%">
            <el-form :model="loginParams">
                <el-form-item label="用户账号:" label-width="100px" prop="username">
                    <el-col :span="18">
                        <el-input v-model="loginParams.username" autocomplete="off" disabled="disabled"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="用户密码:" label-width="100px" prop="password">
                    <el-col :span="18">
                        <el-input type="password" v-model="loginParams.password" autocomplete="new-password"></el-input>
                    </el-col>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelLogin">取 消</el-button>
                <el-button type="primary" @click="login" :loading="isLoading">登 录</el-button>
            </div>
        </el-dialog>
    </div>

</template>
<script>
    import Header from '../components/Header.vue';
    import Nav from '../components/Nav.vue';
    import Tabs from '../components/Tabs.vue';
    import bus from '../assets/js/bus';
    import Vue from 'vue'
    import UserService from '../services/User.js'
    import Wxchat from '../components/Wxchat.vue';

    export default {
        name: "Index",
        data() {
            return {
                sideBarWidth: 240,
                leftWidth: 240,
                showLogoutAlert: false,
                promptTimes: 0,
                dialogWxchatVisible: false,
                chatUid: '',
                loginParams: {
                    username: this.$user.getName(),
                    password: ''
                },
                isLoading: false,
                topH: 0
            }
        },
        components: {
            Header, Nav, Tabs, Wxchat
        },
        mounted() {
            this.init();
            this.$eventHub.$on('_OPEN_WX_CHAT_', (id) => {
                this.chatUid = parseInt(id)
                this.dialogWxchatVisible = true
            })
            Vue.prototype.$eventHub.$on('USER_LOGIN_EXPIRED', () => {
                this.promptTimes++
                if (this.promptTimes == 1) {
                    this.showLogoutAlert = true
                    //this.showLoginPrompt()
                }
                //this.showLogoutAlert = true
                // this.$alert('你的登陆已失效,请重新登陆', '登陆失效', {
                //     confirmButtonText: '确定',
                //     callback: () => {
                //         UserService.logout(() => {
                //             this.$router.push({path: '/login'})
                //         })
                //     }
                // });
            })
            window.addEventListener('scroll', this.handleScroll, true)
        },
        methods: {
            handleScroll() {
                this.$store.commit('setIndexTop', this.$refs.container.offsetTop)
                this.$store.commit('setEditTop', this.$refs.container.getBoundingClientRect().top)
            },
            cancelLogin() {
                UserService.logout(() => {
                    this.$router.push('/login')
                })
            },
            login() {
                if (!this.loginParams.password) {
                    return this.$message.error('请输入密码')
                }
                this.isLoading = true;
                this.$api.user.login(this.loginParams).then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message)
                    } else {
                        this.showLogoutAlert = false
                        this.promptTimes = 0
                    }
                }).catch(error => {
                    this.$message.error(error)
                }).finally(() => {
                    this.isLoading = false;
                })
            },
            showLoginPrompt() {
                let userName = this.$user.getName()
                this.$prompt('你的登录已过期,请输入密码重新登录', '登陆过期', {
                    inputType: 'text',
                    confirmButtonText: '确定',
                    showClose: false,
                    showCancelButton: false,
                    closeOnClickModal: false,
                    closeOnPressEscape: false
                }).then(({value}) => {
                    this.$loading.show('登录中');
                    this.$api.user.login({username: userName, password: value}).then(result => {
                        if (result.errCode) {
                            this.$message.error(result.message)
                            return this.showLoginPrompt()
                        }
                        this.promptTimes = 0
                    }).catch(error => {
                        this.$message.error(error)
                    }).finally(() => {
                        this.$loading.close();
                    })
                }).catch(() => {

                });
            },
            init() {
                if (this.$route.path == '/home') {
                    this.sideBarWidth = 80;
                    this.leftWidth = 100;
                }
            },
            goLogin() {
                UserService.logout(() => {
                    this.$router.push({path: '/login'})
                })
            },
            closeWxchat() {
                this.dialogWxchatVisible = false
            }
        },
        watch: {
            $route(newValue, oldValue) {
                this.$store.commit('setRoutePath', {
                    current: newValue.fullPath,
                    previous: oldValue.fullPath
                });
                if (this.$route.path == '/home') {
                    this.sideBarWidth = 80;
                    this.leftWidth = 100;
                } else {
                    this.sideBarWidth = 240;
                    this.leftWidth = 240;
                }
            }
        },
        created() {
            bus.$on('collapse', msg => {
                this.collapse = msg;
            })

            // 只有在标签页列表里的页面才使用keep-alive，即关闭标签之后就不保存到内存中了。
            bus.$on('tags', msg => {
                let arr = [];
                for (let i = 0, len = msg.length; i < len; i++) {
                    msg[i].name && arr.push(msg[i].name);
                }
                this.tagsList = arr;
            })
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.handleScroll)
        }
    }
</script>

<style>
    .el-container {
        height: 100%;
        background-color: rgba(240, 242, 245, 1);
    }

    .el-header {
        /*background-color: #242f42;*/
        background-color: rgba(55, 61, 65, 1);
        color: #fff;
        height: 70px !important;
        line-height: 60px;
    }

    .el-aside {
        color: #333;
        /*background-color: rgb(50, 65, 87);*/
        background-color: rgba(51, 55, 68, 1);
    }

    .el-main {
        margin-top: 50px;
        padding-top: 15px;
        padding-bottom: 85px;
    }

    .nj-container {
        background-color: #fff;
        padding: 20px;
    }
</style>