<template>
    <div class="header-wrapper">
        <el-header>
            <el-row :gutter="24">
                <el-col :span="12">
                    <div class="header-icon">
                        <img class="header-logo" style="margin-top: 13px;" src="../assets/logo.png">
                        <!--                        <i class="el-icon-menu"></i>-->
                        <!--                        <div class="manage-name" style="">-->
                        <!--                            联简在线-->
                        <!--                            <div class="fs-12">监测站管理系统2.0</div>-->
                        <!--                        </div>-->

                    </div>
                </el-col>

                <el-col :span="12" style="text-align: right;overflow: hidden;">
                    <div class="fr">
                        <el-link @click="toNotice()" v-if="userInfo.is_station == 1"><i class="el-icon-bell i-mark"><em
                                class="mark"
                                v-show="readStatus != 1"></em></i>
                            消息
                        </el-link>
                        <el-link v-if="userInfo.master" @click="loginBack"><i class="el-icon-refresh"></i> 切换</el-link>
                        <el-dropdown @command="handleCommond" trigger="click">
                            <span class="el-dropdown-link txt-center">
                                  {{userInfo.u_name}}  <i class="el-icon-arrow-down"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <!--
                                <el-dropdown-item command="reset_avator">修改头像</el-dropdown-item>
                                -->
                                <el-dropdown-item command="reset_password">修改密码</el-dropdown-item>
                                <el-dropdown-item command="logout">退出系统</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>

                </el-col>
            </el-row>
        </el-header>
    </div>
</template>

<script>
    export default {
        name: 'Header',
        data() {
            return {
                url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
                search: '',
                userInfo: {},
                readStatus: 0,
                station: '',//检测站
                stationList: [],
            }
        },
        mounted() {
            this.$user.checkLogin(info => {
                this.userInfo = info
            });
        },
        methods: {
            tolink(url) {
                this.$router.push(url)
            },
            loginBack() {
                this.$message.confirm({message: '确定要切换登录吗'}, () => {
                    this.$loading.show('登陆中')
                    this.$api.user.loginBack({}).then(result => {
                        if (result.errCode) {
                            this.$message.alert(result.message)
                        } else {
                            this.$message.success('登陆成功');
                            this.$user.reset(() => {
                                this.$eventHub.$emit("user_changed")
                            })
                        }
                    }).catch(error => {
                        this.$message.error(error)
                    }).finally(() => {
                        this.$loading.close()
                    })
                })
            },
            goLogin() {
                this.$router.push({path: '/login'})
            },
            handleCommond(command) {
                switch (command) {
                    case 'logout':
                        this.logout();
                        break;
                    case 'reset_password':
                        this.$router.push('/user/password');
                        break;
                    case 'reset_avator':
                        this.$router.push('/user/avator');
                        break;
                }
            },
            logout() {
                this.$message.confirm({message: '确定要退出登录吗'}, () => {
                    this.$loading.show('退出登录中')
                    this.$user.logout(() => {
                        this.$loading.close()
                        this.$store.commit('menuRouteLoaded', false)
                        this.$router.push('/login')
                    })
                })
            },

            goBack() {
                console.log('go back');
            },

            toNotice() {
                this.$router.push('/notice');
            },
            //获取公告列表读取状态
            getReadStatus() {
                this.$api.management.readNoticeStatus().then(result => {
                    if (result.errCode) {
                        this.$message.error(result.message);
                    } else {
                        this.readStatus = result.data.all_read;
                    }
                }).catch(error => {
                    this.$message.error(error);
                });
            },
        }
    }
</script>
<style scoped lang="less">
    .header-wrapper .el-header {
        height: 70px !important;
        line-height: 70px;
    }

    .header-icon {
        padding-top: 5px;
        margin-left: -10px;
        line-height: initial;
    }

    .header-logo {
        height: 35px;
        vertical-align: -2px;
    }

    .manage-name {
        margin-top: 10px;
        font-weight: 700;
        display: inline-block;
        margin-left: 10px;
    }

    .header-icon .el-icon-menu {
        position: absolute;
        left: 10px;
        top: 15px;
        font-size: 35px;
    }

    .el-dropdown {
        color: #fff;
    }

    .el-dropdown-link {
        cursor: pointer;
    }

    .el-link {
        margin-right: 30px;
        vertical-align: top;
        color: #fff !important;
    }

    .header-station {
        position: relative;
        min-width: 320px;

        .el-select {
            width: 100%;
            z-index: 11;
            outline-color: rgba(55, 61, 65, 1);
        }

        .el-select:hover .el-input__inner {
            border-color: rgba(55, 61, 65, 1);
        }

        .el-input__inner {
            background-color: transparent;
            color: #fff;
            border: 1px solid rgba(55, 61, 65, 1);
            font-size: 18px;
            font-weight: 700;
        }

        .el-select .el-input__inner:focus {
            border-color: transparent;
        }

        .change {
            position: absolute;
            right: 10%;
            top: 0;
            font-size: 14px;
            line-height: 74px;
            z-index: 10;
        }

    }


</style>
